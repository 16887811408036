import React from 'react';
import PropTypes from 'prop-types';
import TitleComponent from '../TitleComponent';
import styles from './Index.module.css';
import TextLoop from 'react-text-loop';
import {
  Typography,
  Grid,
  Button,
  Box,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  Hidden,
  Paper,
} from '@material-ui/core';
import {LinkedIn, Twitter} from '@material-ui/icons';
import {withStyles} from '@material-ui/core/styles';

const direct_styles = theme => ({
  float_up: {[theme.breakpoints.down('md')]: {order: 1}},
  float_down: {[theme.breakpoints.down('md')]: {order: 2}},
  xs_center: {
    [theme.breakpoints.down('md')]: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  minHeightSmall: {[theme.breakpoints.down('md')]: {minHeight: '150px'}},
});

const Index = (props) => {
  return (
      <Grid container
            direction="column"
            justify="center"
            alignItems="center"
            className={styles.indexContainer}
      >
        <TitleComponent title={''}/>

        {/* First line */}
        <Grid item xs={12}>
          <Grid container
                direction="row"
                justify="center"
                alignItems="center"
          >
            <Grid item xs={12} lg={6}
                  className={`${styles.headlingText} ${props.classes.float_down}`}>
              <Box p={2}>

                <Typography gutterBottom variant="h2" component="h1"
                            className={styles.headline}>
                  65% of high-potential startups fail as a result of conflict
                  among co-founders
                </Typography>
                <Typography className={styles.headline_attribution}>
                  - Noam Wasserman, professor at Harvard Business School in "The
                  Founder's Dilemma"
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6}
                  className={`${styles.conflictContainer} ${props.classes.float_up}`}>
              <img className={styles.team} alt="Team conflicts"
                   src={process.env.PUBLIC_URL + '/imgs/home/conflict.png'}/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} className={styles.call_to_action}>
          <Box p={2} pt={20}>
            <Typography variant="h5" component="h2">
              We prevent personal misalignment from killing your early-stage
              startup
            </Typography>
            <Box pt={3}>
              <Button align="center" variant="contained"
                      className={styles.actionButton} href="/team/create">
                <Box p={2}>
                  <Typography variant='h5'>Check alignment now!</Typography>
                </Box>
              </Button>
            </Box>
          </Box>
        </Grid>


        {/* Demo */}
        <Grid item xs={12} className={styles.demo_section}>
          <Box p={2} pt={20}>
            <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={3}
                  stype={{'background':'red'}}>
              <Grid item xs={12} lg={12}>
                <Typography variant="h4" gutterBottom
                            className={props.classes.minHeightSmall}>
                  Have you ever had a conversation about {' '}
                  <TextLoop springConfig={{stiffness: 180, damping: 10}}
                            mask={true}>
                    <span className={styles.blue}>the exit case</span>
                    <span className={styles.blue}>your burn rates</span>
                    <span className={styles.blue}>how to split equity</span>
                    <span className={styles.blue}>hiring above you</span>
                  </TextLoop>?
                </Typography>
              </Grid>
              <Grid item xs={12} lg={8}>
                <Typography variant="body1">
                  In euphoric phases in the beginning cofounders often avoid
                  difficult conversations, so that major value, goal and opinion
                  differences remain undisclosed. Aligna.Team helps to get a
                  clear
                  picture of your internal team alignment situation. Start
                  aligning now!</Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="h5" gutterBottom>Sample
                  question</Typography>
                <Paper elevation={7}>
                  <Box p={3}>
                    <img style={{height: 'auto', width: '100%'}}
                         src={process.env.PUBLIC_URL + '/imgs/home/demo/3.png'}
                         alt="Sample questionnaire question"/>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>


        <Grid item xs={12} className={styles.winning_section}>
          <Box p={2}>
            <Grid container direction="column" justify="center"
                  alignItems="center">
              <Grid item xs={12} md={6} lg={3}>
                <img className={styles.winning_team}
                     src={process.env.PUBLIC_URL +
                     '/imgs/home/winningteam.png'}
                     alt="Winning team"/>
                <Box mt={5} mb={5}>
                  <Typography variant="h3"
                              className={styles.secondary_headlines}>
                    Build a winning team
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} md={12} lg={10} xl={6}>
                <Grid container direction="row" justify="center"
                      alignItems="stretch" spacing={2}>
                  <Grid item xs={12} md={4}>
                    <Card className={styles.customCard} elevation={3}>
                      <CardHeader
                          classes={{title: styles.customCardHeader}}
                          title="1"
                          subheader="Create Team"
                      />
                      <CardMedia className={styles.customCardMedia}>
                        <img src={process.env.PUBLIC_URL +
                        '/imgs/home/step_1.png'}
                             alt="Create a team"/>
                      </CardMedia>
                      <CardContent>
                        <Typography variant="body1" component="p">
                          Create your team and invite your cofounders ...
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card className={styles.customCard} elevation={3}>
                      <CardHeader
                          classes={{
                            title: styles.customCardHeader,
                          }}
                          title="2"
                          subheader="Answer Questions"
                      />
                      <CardMedia className={styles.customCardMedia}>
                        <img src={process.env.PUBLIC_URL +
                        '/imgs/home/step_2.png'}
                             alt="Fill out questionnaire"/>
                      </CardMedia>
                      <CardContent>
                        <Typography variant="body1" component="p">
                          Answer the questions and wait for your cofounders to
                          do same ...
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Card className={styles.customCard} elevation={3}>
                      <CardHeader
                          classes={{title: styles.customCardHeader}}
                          title="3"
                          subheader="Get Analysis"
                      />
                      <CardMedia className={styles.customCardMedia}>
                        <img
                            src={process.env.PUBLIC_URL +
                            '/imgs/home/step_3.png'}
                            alt="Analyse team alignment"/>
                      </CardMedia>
                      <CardContent>
                        <Typography variant="body1" component="p">
                          Aligna.Team calculates your team's alignment score
                          with one click and you can discuss the result with
                          your team.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/* Quote */}
        <Grid item xs={12} className={styles.quote_section}>
          <Box p={2} pt={20}>
            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="flex-end">
              <Grid item xs={12} lg={6}>
                <blockquote cite="">
                  <p className={styles.quote}>&quot;In YC’s case, the number one
                    cause of early death for startups is cofounder
                    blowups.&quot;</p>
                  <footer>— Sam Altman, <cite>Chairman of Y Combinator</cite>
                  </footer>
                </blockquote>
                <Box mt={3}>
                  <Button align="center" variant="contained"
                          className={styles.actionButton} href="/team/create">
                    <Typography>Check your teams alignment now</Typography>
                  </Button></Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        <Grid item className={styles.team_section}>
          <Box p={2} pt={10}>
            <Typography variant="h3" gutterBottom
                        className={styles.secondary_headlines}>
              Team
            </Typography>

            <Grid container
                  direction="column"
                  justify="flex-start"
                  alignItems="flex-start"
                  spacing={5}>
              <Grid item xs={12} className={styles.team_container}>
                <Grid container
                      direction="row"
                      justify="flex-start"
                      alignItems="flex-end"
                      spacing={3}
                >
                  <Grid item xs={12} md={4} lg={2}
                        className={props.classes.float_down}>
                    <img
                        className={`${styles.team_profile_image} ${props.classes.xs_center}`}
                        src={process.env.PUBLIC_URL +
                        '/imgs/team/linus.png'}
                        alt="Linus Kohl"/>
                  </Grid>
                  <Grid item xs={12} md={8} lg={4}
                        className={props.classes.float_up}>
                    <div className={[styles.bubble, styles.bubble_right].join(
                        ' ')}>This topic is badly neglected. Trust your gut
                      feeling and
                      never ignore it. In a second step, talk about the tough
                      questions and
                      don't try to be agreeable.
                    </div>
                    <Box style={{textAlign: 'left'}}>
                      <Typography component="h4" className={styles.team_name}>
                        Linus Kohl</Typography>
                      <Typography>Science and Technology</Typography>
                      <a rel="noopener noreferrer" target="_blank"
                         href="https://www.linkedin.com/in/linuskohl/"><LinkedIn
                          fontSize="large"/></a>
                      <a rel="noopener noreferrer" target="_blank"
                         href="https://twitter.com/linuskohl"><Twitter
                          fontSize="large"/></a>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>


            <Grid item xs={12} className={styles.team_container}>
              <Grid container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-end"
                    spacing={3}
              >
                <Hidden mdDown>
                  <Grid item xs={1} lg={6}></Grid>
                </Hidden>
                <Grid item xs={12} md={8} lg={4}>
                  <div className={[styles.bubble, styles.bubble_left].join(
                      ' ')}>If I would have known the contradicting personal
                    goals of my cofounders earlier on, I probably would have
                    realized we are not a great fit.
                    Sharing the same values and goals is key to every
                    early-stage startup.
                  </div>
                  <Box style={{textAlign: 'right'}}>
                    <Typography component="h4" className={styles.team_name}>
                      Robin Betz</Typography>
                    <Typography>Marketing</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} lg={2}>
                  <img
                      className={`${styles.team_profile_image} ${props.classes.xs_center}`}
                      src={process.env.PUBLIC_URL +
                      '/imgs/team/robin.png'}
                      alt="Robin Betz"/>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>


        <Grid item className={styles.reference_section}>
          <Box p={3} pt={25}>
            <Typography variant="h3" gutterBottom className={styles.secondary_headlines}>
              Opinions
            </Typography>

            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={3}>
              <Grid item xs={12} className={styles.reference_container}>
                <Grid container
                      direction="row"
                      justify="center"
                      alignItems="center"
                      spacing={3}
                >
                  <Grid item xs={12} md={8} lg={8}>
                    <blockquote cite="">
                      <p className={styles.opinion}>
                        &quot;As a mediator for founders and SME, I accompany
                        founding teams from the idea to the memorandum of
                        association. In my opinion, the Aligna.team questionnaire
                        is the perfect preparation for the first workshop meeting.
                        It encourages all founders to think about themselves and
                        their motivators. These play a crucial role in the
                        founding team&apos;s long-term cooperation and the success of
                        the joint venture. A sustainable common ground can only be
                        found through intensive dialogue with the other founders.&quot;
                      </p>
                      <footer style={{float: 'right'}}>— <cite>
                        <a rel="noopener noreferrer" target="_blank" className={styles.opinion_author}
                                   href="https://www.mz-mediation.de">
                        <Typography component="span">Manuela Zehender</Typography>
                      </a>
                      </cite>, Founder MZ-Mediation
                      </footer>
                    </blockquote>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Grid>

        {/*Featured*/}
        <Grid item className={styles.press_section} xs={12}>
          <Box p={2} pt={10}>
            <Typography variant="h3" gutterBottom className={styles.secondary_headlines}>
              In the press
            </Typography>

            <Grid container
                  direction="column"
                  justify="center"
                  alignItems="center"
                  spacing={3}>
              <Grid item xs={12} md={4}>
                  <a rel="noopener noreferrer" target="_blank"
                     href="https://www.munich-startup.de/56356/7-fragen-alignateam/">
                  <img
                      className={`${styles.press_logo} ${props.classes.xs_center}`}
                      src={process.env.PUBLIC_URL + '/imgs/press/munichstartup.png'}
                      alt="Munich Startups"/>
                  </a>
              </Grid>
            </Grid>
          </Box>
        </Grid>


        {/* Support */}
        <Grid item xs={12} lg={5} className={styles.reason_section}>
          <Box p={2} pt={10}>
            <Typography variant="h3" className={styles.secondary_headlines}
                        gutterBottom style={{textAlign: 'center'}}>
              Support our cause
            </Typography>

            <Box style={{textAlign: 'center'}} pt={3}>
              <Typography variant="body1" component="span">
                Aligna.Team is a non-profit project for founders by founders and
                made with
                <Typography component="p" variant="h3"
                            style={{color: '#fe6b8b'}}>❤</Typography>️ in
                Munich, Germany.<br/>
              </Typography>
            </Box>
            <Typography variant="body1" style={{textAlign: 'center'}}>
              If you want to support startups too, contact us at <a
                href="mailto:hello@aligna.team" target="_blank"
                rel="noopener noreferrer">hello@aligna.team</a>
            </Typography>

            <Box mt={15}>
              <Grid container
                    direction="row"
                    justify="center"
                    alignItems="flex-end"
                    spacing={3}>
                <Grid item xs={12} md={6}>
                  <a href="https://aws.amazon.com/de/activate/" target="_blank"
                     rel="noopener noreferrer"><img
                      className={styles.supporterLogo}
                      src={process.env.PUBLIC_URL +
                      '/imgs/supporters/aws_activate.png'}
                      alt="Amazon Web Services Activate logo"/></a>
                </Grid>
                <Grid item xs={12} md={6}>
                  <a href="https://developer.ibm.com/startups/" target="_blank"
                     rel="noopener noreferrer">
                    <img
                        className={styles.supporterLogo}
                        src={process.env.PUBLIC_URL +
                        '/imgs/supporters/ibm-cloud.png'}
                        alt="Startup with IBM logo"/></a>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
  );
};

Index.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(direct_styles)(Index);
